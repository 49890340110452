<template>
    <ICountUp :delay="500" :end-val="Number(value)" :options="options" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ICountUp from 'vue-countup-v2';

const ACountUpProps = Vue.extend({
    props: {
        value: {
            type: Number,
            required: true
        },
        prefix: {
            type: String,
            default() {
                return '';
            }
        },
        suffix: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component({
    components: { ICountUp }
})
export default class ACountUp extends ACountUpProps {
    get delay() {
        return 500;
    }

    get options() {
        return {
            useEasing: true,
            useGrouping: true,
            separator: ',',
            prefix: this.prefix,
            suffix: this.suffix
        };
    }
}
</script>
