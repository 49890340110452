import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_vm.loading)?_c(VCard,{staticClass:"loader--wrapper rounded-xl",attrs:{"flat":"","height":"27em"}},[_c(VCardText,[_c(VSkeletonLoader,{attrs:{"type":"heading"}}),_c(VSkeletonLoader,{staticClass:"mt-4",attrs:{"type":"text","width":"70%"}}),_c(VSkeletonLoader,{staticClass:"mt-4 image-loader",attrs:{"type":"image"}})],1)],1):_c(VCard,{staticClass:"stats--wrapper rounded-xl",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"font-weight-normal black--text justify-center"},[_vm._v(" Amp Search Appearances "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({attrs:{"right":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" circle-info ")])]}}])},[_c('span',[_vm._v(" Total number of Google search "),_c('br'),_vm._v(" ranking results for content published "),_c('br'),_vm._v(" through AmpiFire ")])])],1),_c(VCardText,[_c('keyword-rankings-chart',{attrs:{"data":_vm.data,"show-customer-average":_vm.showCustomerAverage}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }