import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"slider-container"},[_c(VContainer,{staticClass:"slider-wrapper"},[_c(VCarousel,{staticClass:"slider",attrs:{"light":"","hide-delimiter-background":"","delimiter-icon":"square","height":"340px","interval":"8000","cycle":""},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"large":"","icon":"","ripple":false}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("angle-left")])],1)]}},{key:"next",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"large":"","icon":"","ripple":false}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("angle-right")])],1)]}}])},_vm._l((_vm.slides),function(slide,index){return _c(VCarouselItem,{key:'slide-' + index,attrs:{"reverse-transition":"fade-transition","transition":"fade-transition"}},[_c(VContainer,{staticClass:"fill-height align-start align-sm-center px-0"},[_c(VRow,[_c(VCol,{staticClass:"text-center text-sm-left",attrs:{"cols":"10","offset":"1","sm":"10","offset-sm":"1","md":"8","offset-md":"1"}},[_c('h4',{staticClass:"text-h6 text-sm-h5 text-md-h4 mb-5 font-weight-bold"},[_vm._v(" "+_vm._s(slide.title)+" ")]),_c('p',{staticClass:"text-body-2 text-sm-body-1 text-md-h6 mb-6 font-weight-regular",domProps:{"innerHTML":_vm._s(slide.body)}}),_c('div',{staticClass:"d-flex flex-wrap justify-center justify-sm-start mt-14 mt-sm-0"},[(slide.primaryActionButton)?_c(VBtn,{staticClass:"mt-6 mt-sm-0 mb-2",attrs:{"href":slide.primaryActionButton.actionUrl,"color":"primary"}},[_vm._v(" "+_vm._s(slide.primaryActionButton.text)+" ")]):_vm._e(),(slide.secondaryActionButton)?_c(VBtn,{staticClass:"ml-sm-4",attrs:{"link":"","to":slide.secondaryActionButton
                                            .actionUrl}},[_vm._v(" "+_vm._s(slide.secondaryActionButton.text)+" ")]):_vm._e()],1)])],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }