<template>
    <v-container>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="500">
                <v-card class="modal-wrapper">
                    <v-card-title class="modal-title justify-center">
                        <h5 class="secondary--text py-4 font-weight-light">
                            Welcome To AmpiFire, Home Of
                        </h5>

                        <v-img
                            class="mx-auto mt-0 mb-4"
                            max-width="300px"
                            style="display: block"
                            alt="Simpler Traffic"
                            src="@/assets/img/logo.simplertraffic.com.svg"
                        />
                    </v-card-title>
                    <v-card-text class="modal-body">
                        <v-stepper v-model="onboarding" elevation="0">
                            <v-stepper-items>
                                <v-stepper-content step="1" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="text-h6">
                                            Training Begins On Friday 3rd
                                            November
                                        </v-card-subtitle>

                                        <v-card-text>
                                            <p>You Paid For This!</p>
                                            <p>
                                                Make sure you're registered for
                                                the live training sessions so
                                                you can start setting up your
                                                Simpler Sites ASAP.
                                            </p>
                                            <p>
                                                We're going to walk through the
                                                entire process together,
                                                step-by-step, so make sure you
                                                show up live and early!
                                            </p>
                                            <p>
                                                <v-btn
                                                    href="https://go.ampifire.ai/SimplerTrafficTraining"
                                                    target="_blank"
                                                    color="primary"
                                                >
                                                    REGISTER FOR SIMPLER TRAFFIC
                                                    TRAINING HERE
                                                </v-btn>
                                            </p>
                                        </v-card-text>

                                        <v-card-actions class="justify-end">
                                            <v-btn @click="onboarding = 2">
                                                Next
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-content step="2" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="text-h6">
                                            Scale Up Your Simpler Traffic
                                            Business
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <p>
                                                To launch even more Simpler
                                                Sites or boost your most
                                                Profitable Posts, you will need
                                                to do more Promotion.
                                            </p>
                                            <p>
                                                This isn't mandatory but as with
                                                everything, the more you invest
                                                into your business, the more you
                                                stand to make.
                                            </p>
                                            <p>
                                                This offer will let you Launch a
                                                brand new site or boost one of
                                                your best posts each and every
                                                month.
                                            </p>
                                            <p>
                                                And as a New Simpler Traffic
                                                student, you can lock in a
                                                LIFETIME discount on Promotions
                                                that will save you thousands of
                                                dollars over the course of the
                                                next year.
                                            </p>
                                            <p>
                                                <v-btn
                                                    color="primary"
                                                    href="https://simplertraffic.com/route_sale?fa=0"
                                                    target="_blank"
                                                >
                                                    ACTIVATE YOUR LIFETIME
                                                    DISCOUNT
                                                </v-btn>
                                            </p>
                                            <p>
                                                <i>
                                                    (Note: You pay nothing extra
                                                    today to lock this in)
                                                </i>
                                            </p>
                                        </v-card-text>
                                        <v-card-actions
                                            class="justify-space-between"
                                        >
                                            <v-btn @click="onboarding = 1">
                                                Previous
                                            </v-btn>
                                            <v-btn @click="onboarding = 3">
                                                Next
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-content step="3" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="text-h6">
                                            Join The Private Facebook Group
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <p>
                                                Being in the company of
                                                motivated hard-working people
                                                just like you, is going to be
                                                the best possible environment
                                                for your success.
                                            </p>
                                            <p>
                                                <v-btn
                                                    color="primary"
                                                    href="https://www.facebook.com/groups/simplertraffic"
                                                    target="_blank"
                                                >
                                                    JOIN PRIVATE FACEBOOK GROUP
                                                </v-btn>
                                            </p>
                                        </v-card-text>

                                        <v-card-actions
                                            class="justify-space-between"
                                        >
                                            <v-btn @click="onboarding = 2">
                                                Previous
                                            </v-btn>
                                            <v-btn @click="onboarding = 4">
                                                Next
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-content step="4" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="text-h6">
                                            Book Your 1-To-1 Session!
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <p>
                                                When you invested in “Simpler
                                                Traffic”, as a bonus you were
                                                given a free one-to-one strategy
                                                session, with one of our Simpler
                                                Traffic Experts. This is going
                                                to help you define your own
                                                personal strategy. So you’ll
                                                know exactly what to do, from
                                                Day 1, to reach your profit goal
                                                ASAP.
                                            </p>
                                            <p>
                                                These sessions usually cost $500
                                                but this one is free!
                                            </p>
                                            <p>
                                                <v-btn
                                                    color="primary"
                                                    href="https://simplertraffic.com/thankyou"
                                                    target="_blank"
                                                >
                                                    BOOK YOUR 1-TO-1 HERE
                                                </v-btn>
                                            </p>
                                        </v-card-text>
                                        <v-card-actions
                                            class="justify-space-between"
                                        >
                                            <v-btn @click="onboarding = 3">
                                                Previous
                                            </v-btn>
                                            <v-btn @click="dialog = false">
                                                Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class SimplerTrafficWelcome extends Vue {
    onboarding = 1;
    dialog = true;
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    &.v-card > .v-card__text {
        color: $secondary-color;
    }

    &.modal-title {
        border-top: $primary-color 6px solid;
    }
}
</style>
