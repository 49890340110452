import Dashboard from './Dashboard.vue';

export default Dashboard;

export type KeywordRankingsData = {
    first: Array<number>;
    generic: Array<number>;
    topTen: Array<number>;
};

export type AmpRate = {
    current: number;
    previous: number;
    comparison: number;
};

export type AmpData = {
    requested: number;
    attention: number;
    published: number;
};

export type AmpStatsData = {
    ampData: AmpData;
    ampRate: AmpRate;
};

export type LinkCount = {
    total: number;
    latest: number;
};

export type BrandData = {
    linkCount: LinkCount;
    momentumRate: number;
};

export type HtmlLegendPluginOptions = {
    containerID: string;
};
