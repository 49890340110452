<template>
    <v-row>
        <v-col cols="6">
            <v-card flat class="stat-card" to="/author-requests">
                <v-card-text v-if="loading">
                    <v-skeleton-loader type="avatar" />

                    <v-skeleton-loader class="mt-2" type="heading" />
                </v-card-text>

                <v-card-text v-else>
                    <a-count-up
                        class="stat-card--count"
                        :value="data.ampData.requested"
                    />

                    <p class="stat-card--text">Amps Requests in Queue</p>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="6">
            <v-card flat class="stat-card">
                <v-card-text v-if="loading">
                    <v-skeleton-loader type="avatar" />

                    <v-skeleton-loader class="mt-2" type="heading" />
                </v-card-text>

                <v-card-text v-else>
                    <amp-rate :data="data.ampRate" />
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="6" sm="12">
            <v-card flat class="stat-card" to="/announcements">
                <v-card-text v-if="loading">
                    <v-skeleton-loader type="avatar" />

                    <v-skeleton-loader class="mt-2" type="heading" />
                </v-card-text>

                <v-card-text v-else>
                    <a-count-up
                        class="stat-card--count"
                        :value="data.ampData.attention"
                    />

                    <p class="stat-card--text">Amps Needing Action</p>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="6" sm="12">
            <v-card flat class="stat-card" to="/announcements">
                <v-card-text v-if="loading">
                    <v-skeleton-loader type="avatar" />

                    <v-skeleton-loader class="mt-2" type="heading" />
                </v-card-text>

                <v-card-text v-else>
                    <a-count-up
                        class="stat-card--count"
                        :value="data.ampData.published"
                    />

                    <p class="stat-card--text">Amps Published</p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { AmpStatsData } from '.';

import { ACountUp } from '@/components/ACountUp';
import AmpRate from './AmpRate.vue';

const AmpStatsProps = Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        data: {
            type: Object as PropType<AmpStatsData>,
            required: true
        }
    }
});

@Component({
    components: { ACountUp, AmpRate }
})
export default class AmpStats extends AmpStatsProps {}
</script>

<style lang="scss" scoped>
.stat-card {
    border-radius: 24px !important;
    height: 8em;

    .stat-card--count {
        display: inline-block;
        background-color: $primary-color;
        padding: 8px;
        min-width: 3em;
        text-align: center;
        border-radius: 12px;
        color: white;
        font-weight: bold;
    }

    .stat-card--text {
        font-weight: 500;
        font-size: 14px;
        margin-top: 0.5em;
        color: black;
    }
}
</style>
