<template>
    <v-container>
        <v-row class="mt-8">
            <v-col cols="12">
                <dashboard-message
                    :loading="loading.message"
                    :has-published-amps="hasPublishedAmps"
                    :client-id="String(currentClientId)"
                />
            </v-col>
        </v-row>

        <v-row class="mt-16">
            <v-col
                class="d-flex align-start flex-column flex-sm-row align-sm-center"
            >
                <label
                    class="font-weight-bold text-uppercase mr-4 explore-progress"
                >
                    Explore progress for
                </label>
                <div
                    v-if="isReseller"
                    class="d-flex align-center justify-center reseller-field-static-width"
                >
                    <v-autocomplete
                        v-if="isReseller"
                        v-model="currentClientId"
                        class="mr-sm-6 mb-4 mb-sm-0"
                        label="Client"
                        hint="Type or select a client"
                        persistent-hint
                        :disabled="requesting"
                        :loading="requesting"
                        :items="clients"
                        item-value="id"
                        item-text="email"
                        hide-no-data
                        hide-selected
                        persistant-hint
                        hide-details
                        solo
                    >
                        <template #item="data">
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-layout wrap v-bind="attrs" v-on="on">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.email }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-layout>
                                </template>
                                <span>{{ `${data.item.email}` }}</span>
                            </v-tooltip>
                        </template>
                    </v-autocomplete>
                </div>

                <div
                    class="d-flex align-center justify-center company-field-static-width"
                >
                    <v-autocomplete
                        v-model="currentCompanyId"
                        label="Company"
                        hint="Type or select a company"
                        persistent-hint
                        :disabled="requesting"
                        :loading="requesting"
                        :items="companies"
                        item-text="name"
                        hide-no-data
                        item-value="id"
                        hide-details
                        class="v-size--x-large"
                        solo
                    >
                        <template #item="data">
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-layout wrap v-bind="attrs" v-on="on">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-layout>
                                </template>
                                <span>{{ `${data.item.name}` }}</span>
                            </v-tooltip>
                        </template>
                    </v-autocomplete>

                    <v-btn class="ml-6" icon x-small @click="getMetrics">
                        <v-icon>fa-refresh</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="align-center justify-center pb-12">
            <v-col cols="12" sm="6" lg="4">
                <Amp-stats :loading="loading.ampStats" :data="ampStats" />
            </v-col>

            <template v-if="!showDefaultStats || loading.ampStats">
                <v-col cols="12" sm="6" md="6" lg="4">
                    <brand-stats
                        key="brand-stats-1"
                        :loading="loading.brand"
                        :data="brandData"
                    />
                </v-col>

                <v-col cols="12" sm="12" md="12" lg="4">
                    <keyword-rankings-stats
                        :loading="loading.keywordRankings"
                        :data="keywordRankings"
                        :show-customer-average="true"
                    />
                </v-col>
            </template>

            <v-col
                v-else
                cols="12"
                sm="12"
                lg="8"
                class="default-stats--container"
            >
                <v-row class="default-stats--wrapper">
                    <v-col cols="12" class="py-0">
                        <p class="header-text">where you can be in 10 amps*</p>
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <brand-stats
                                    key="brand-stats-1"
                                    :loading="loading.brand"
                                    :data="defaultBrandData"
                                />
                            </v-col>

                            <v-col cols="12" sm="6">
                                <keyword-rankings-stats
                                    :loading="loading.keywordRankings"
                                    :data="defaultKeywordRankings"
                                    :show-customer-average="false"
                                />
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" class="pb-0">
                        <p class="footer-text">
                            *Data from average AmpiFire customer after 10 amps
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';
import { mapGetters } from 'vuex';

import type { Company } from '@/types/Company';
import type { AmpStatsData, BrandData, KeywordRankingsData } from '.';
import type { ClientUser } from '@/types/User';

import DashboardMessage from './DashboardMessage.vue';
import AmpStats from './AmpStats.vue';
import BrandStats from './BrandStats.vue';
import KeywordRankingsStats from './KeywordRankingsStats.vue';
import { ClientSelector } from '@/components/ClientSelector';

@Component({
    components: {
        AmpStats,
        BrandStats,
        KeywordRankingsStats,
        DashboardMessage,
        ClientSelector
    },
    computed: {
        ...mapGetters('user', ['isReseller'])
    }
})
export default class Dashboard extends Vue {
    isReseller!: boolean;

    companies: Company[] = [];

    @Watch('companies', { deep: true })
    onComapniesChange(v: Company[]) {
        if (v.length) {
            this.currentCompanyId = v[0].id;

            this.getMetrics();
        } else {
            this.setLoading(false);

            this.showDefaultStats = true;
        }
    }

    clients: ClientUser[] = [];

    hasPublishedAmps = false;

    currentCompanyId: Company['id'] | null = null;

    @Watch('currentCompanyId')
    onCurrentCompanyIdChange(v: Company['id']) {
        if (v) {
            this.getMetrics();
        }
    }

    currentClientId: ClientUser['id'] | null = null;

    @Watch('currentClientId')
    onCurrentClientIdChange(v: number) {
        this.setLoading();

        this.getClientCompanies(v).then((companies: Company[]) => {
            this.companies = companies;
        });
    }

    showDefaultStats = false;

    loading = {
        message: true,
        ampStats: true,
        brand: {
            link_count: true,
            brand_momentum: true
        },
        keywordRankings: true
    };

    ampStats: AmpStatsData = {
        ampData: {
            requested: 0,
            attention: 0,
            published: 0
        },
        ampRate: {
            current: 0,
            previous: 0,
            comparison: 0
        }
    };

    brandData: BrandData = {
        linkCount: {
            latest: 0,
            total: 0
        },
        momentumRate: 0
    };

    defaultBrandData: BrandData = {
        linkCount: {
            latest: 415,
            total: 2350
        },
        momentumRate: 55
    };

    keywordRankings: KeywordRankingsData = {
        topTen: [],
        first: [],
        generic: []
    };

    defaultKeywordRankings: KeywordRankingsData = {
        topTen: [0, 100, 180, 290, 380, 500, 650, 750, 890, 1010, 1300],
        first: [0, 60, 140, 250, 340, 460, 600, 700, 790, 950, 1100],
        generic: []
    };

    get requesting(): boolean {
        return this.$store.state.loading.show;
    }

    mounted() {
        this.setLoading();

        this.getData().then(data => {
            const { companies, users } = data.data;

            if (this.isReseller) {
                this.clients = users;

                this.currentClientId = users[0].id;

                return;
            }

            this.companies = companies;
        });
    }

    getData(userId: ClientUser['id'] | null = null) {
        let url = '/companies/metrics?limit=300';

        if (userId) {
            url = `/companies/metrics?limit=300&user_id=${userId}`;
        }

        return this.$http
            .get(url)
            .then(({ data }) => data)
            .catch(() =>
                this.$store.dispatch(
                    'notification/error',
                    'Cannot load companies. Please try again or contact support.'
                )
            );
    }

    getClientCompanies(userId: ClientUser['id']) {
        return this.getData(userId).then(data => data.data.companies);
    }

    getMetrics() {
        this.showDefaultStats = false;
        this.resetAllStatsData();
        this.getAmpStats();
        this.getKeywordRankings();
        this.getBrandMentions();
        this.getBrandMomentum();
    }

    getAmpStats() {
        this.loading.ampStats = true;

        this.$http
            .get('/announcements/metrics', {
                params: {
                    company_id: this.currentCompanyId,
                    generate: 'amp'
                }
            })
            .then(({ data }) => {
                this.ampStats.ampData = data.data.amp_data;

                this.ampStats.ampRate = data.data.amp_rate;

                if (data.data.latestPublishedPressRelease) {
                    this.hasPublishedAmps = true;
                } else {
                    this.showDefaultStats = true;
                }
            })
            .finally(() => {
                this.loading.message = false;

                this.loading.ampStats = false;
            });
    }

    getKeywordRankings() {
        this.loading.keywordRankings = true;

        this.$http
            .get('/announcements/metrics', {
                params: {
                    company_id: this.currentCompanyId,
                    generate: 'keyword_rankings'
                }
            })
            .then(({ data }) => {
                if (!data.data.success) {
                    return;
                }

                this.keywordRankings = {
                    first: [
                        0,
                        ...Object.values(
                            data.data.keyword_rankings.first as number
                        )
                    ],
                    topTen: [
                        0,
                        ...Object.values(
                            data.data.keyword_rankings.top_ten as number
                        )
                    ],
                    generic: [
                        0,
                        ...Object.values(
                            data.data.keyword_rankings.generic as number
                        )
                    ]
                };
            })
            .finally(() => {
                this.loading.keywordRankings = false;
            });
    }

    getBrandData(type: 'link_count' | 'brand_momentum') {
        this.loading.brand[type] = true;

        return this.$http
            .get('/announcements/metrics', {
                params: {
                    company_id: this.currentCompanyId,
                    generate: type
                }
            })
            .then(({ data }) => data.data)
            .finally(() => {
                this.loading.brand[type] = false;
            });
    }

    getBrandMentions() {
        this.getBrandData('link_count').then(data => {
            if (!data.success) {
                return;
            }
            this.brandData.linkCount = data.link_count;
        });
    }

    getBrandMomentum() {
        this.getBrandData('brand_momentum').then(data => {
            if (!data.success) {
                return;
            }
            this.brandData.momentumRate = Number(
                data.brand_momentum.toFixed(0)
            );
        });
    }

    setLoading(value = true) {
        this.loading = {
            message: value,
            ampStats: value,
            brand: {
                link_count: value,
                brand_momentum: value
            },
            keywordRankings: value
        };
    }

    resetAllStatsData() {
        this.ampStats = {
            ampData: {
                requested: 0,
                attention: 0,
                published: 0
            },
            ampRate: {
                current: 0,
                previous: 0,
                comparison: 0
            }
        };

        this.brandData = {
            linkCount: {
                latest: 0,
                total: 0
            },
            momentumRate: 0
        };

        this.keywordRankings = {
            topTen: [],
            first: [],
            generic: []
        };
    }
}
</script>

<style lang="scss" scoped>
.default-stats--container {
    padding: 1.5em;

    .default-stats--wrapper {
        background-color: #e8f4fe;
        border-radius: 8px;
        padding: 1em;

        .header-text {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1rem;
        }

        .footer-text {
            font-weight: 400;
            font-size: 12px;
        }
    }
}
</style>
