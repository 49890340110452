import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"stat-card",attrs:{"flat":"","to":"/author-requests"}},[(_vm.loading)?_c(VCardText,[_c(VSkeletonLoader,{attrs:{"type":"avatar"}}),_c(VSkeletonLoader,{staticClass:"mt-2",attrs:{"type":"heading"}})],1):_c(VCardText,[_c('a-count-up',{staticClass:"stat-card--count",attrs:{"value":_vm.data.ampData.requested}}),_c('p',{staticClass:"stat-card--text"},[_vm._v("Amps Requests in Queue")])],1)],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"stat-card",attrs:{"flat":""}},[(_vm.loading)?_c(VCardText,[_c(VSkeletonLoader,{attrs:{"type":"avatar"}}),_c(VSkeletonLoader,{staticClass:"mt-2",attrs:{"type":"heading"}})],1):_c(VCardText,[_c('amp-rate',{attrs:{"data":_vm.data.ampRate}})],1)],1)],1),_c(VCol,{attrs:{"cols":"6","sm":"12"}},[_c(VCard,{staticClass:"stat-card",attrs:{"flat":"","to":"/announcements"}},[(_vm.loading)?_c(VCardText,[_c(VSkeletonLoader,{attrs:{"type":"avatar"}}),_c(VSkeletonLoader,{staticClass:"mt-2",attrs:{"type":"heading"}})],1):_c(VCardText,[_c('a-count-up',{staticClass:"stat-card--count",attrs:{"value":_vm.data.ampData.attention}}),_c('p',{staticClass:"stat-card--text"},[_vm._v("Amps Needing Action")])],1)],1)],1),_c(VCol,{attrs:{"cols":"6","sm":"12"}},[_c(VCard,{staticClass:"stat-card",attrs:{"flat":"","to":"/announcements"}},[(_vm.loading)?_c(VCardText,[_c(VSkeletonLoader,{attrs:{"type":"avatar"}}),_c(VSkeletonLoader,{staticClass:"mt-2",attrs:{"type":"heading"}})],1):_c(VCardText,[_c('a-count-up',{staticClass:"stat-card--count",attrs:{"value":_vm.data.ampData.published}}),_c('p',{staticClass:"stat-card--text"},[_vm._v("Amps Published")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }