<template>
    <div>
        <div id="legendsContainer"></div>
        <LineChart
            :chart-options="chartOptions"
            :chart-data="chartData"
            chart-id="keyword-rankings-chart"
            :height="chartHeight"
        />
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Line as LineChart } from 'vue-chartjs/legacy';
import {
    Chart as ChartJS,
    ChartOptions,
    ChartData,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip
} from 'chart.js';

import type { KeywordRankingsData } from '.';
import HtmlLegendPlugin from './HtmlLegendPlugin';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    HtmlLegendPlugin
);

const KeywordRankingsChartProps = Vue.extend({
    props: {
        data: {
            type: Object as PropType<KeywordRankingsData>,
            required: true
        },
        showCustomerAverage: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: { LineChart }
})
export default class KeywordRankingsChart extends KeywordRankingsChartProps {
    get chartOptions(): ChartOptions {
        return {
            responsive: true,
            maintainAspectRatio: false,
            font: {
                family: 'DM Sans'
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    boxHeight: 3,
                    boxWidth: 3,
                    boxPadding: 6
                },
                htmlLegend: {
                    containerID: 'legendsContainer'
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'All Amps'
                    },
                    ticks: {
                        display: true,
                        maxTicksLimit: 10
                    }
                },
                y: {
                    grace: '5%',
                    beginAtZero: true
                }
            },
            datasets: {
                line: {
                    tension: 0.3,
                    borderWidth: 4,
                    pointRadius: 1
                }
            }
        };
    }

    get chartData(): ChartData {
        return {
            labels: this.getLabels(),
            datasets: this.datasets
        };
    }

    get datasets(): ChartData['datasets'] {
        const datasets: ChartData['datasets'] = [
            {
                label: 'Top 10',
                backgroundColor: '#2B478B',
                borderColor: '#2B478B',
                data: this.data.topTen
            },
            {
                label: '#1',
                backgroundColor: '#FE0D66',
                borderColor: '#FE0D66',
                data: this.data.first
            }
        ];

        if (this.showCustomerAverage) {
            datasets.push({
                label: 'Customer Average Ranked in Top 10',
                backgroundColor: '#68A6D8',
                borderColor: '#68A6D8',
                data: this.data.generic
            });
        }

        return datasets;
    }

    get chartHeight() {
        return !this.showCustomerAverage ? 340 : 310;
    }

    getLabels() {
        if (!this.showCustomerAverage) {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        }

        const genericData = Object.keys(this.data.generic);

        return genericData;
    }
}
</script>

<style lang="scss">
#legendsContainer > ul > li:nth-child(3) > span {
    width: 12px !important;
}
</style>
