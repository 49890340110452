import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"rounded-xl",attrs:{"flat":""}},[_c(VRow,[_c(VCol,{staticClass:"pt-0 pr-md-0",attrs:{"cols":"12","md":"7"}},[(_vm.loading)?[_c(VCardText,[_c(VSkeletonLoader,{attrs:{"type":"heading"}}),_c(VSkeletonLoader,{staticClass:"mt-8",attrs:{"type":"sentences"}})],1)]:[_c(VCardTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_c('p',{staticClass:"ma-0 font-weight-regular secondary--text",domProps:{"innerHTML":_vm._s(_vm.message)}}),(_vm.hasPressCableDistributionCredits)?_c('p',{staticClass:"ma-0 mt-2 font-weight-bold"},[_vm._v(" You have PressCable credits remaining. Upgrade to Amp Credits "),_c('router-link',{attrs:{"to":"/profile/credits"}},[_vm._v(" Here "),_c(VIcon,{attrs:{"color":"primary","x-small":""}},[_vm._v(" arrow-right ")])],1)],1):_vm._e()])]],2),_c(VCol,{staticClass:"d-flex align-center justify-center pl-md-0",attrs:{"cols":"12","md":"5"}},[(_vm.loading)?[_c(VSkeletonLoader,{staticClass:"mr-2",attrs:{"type":"button"}}),_c(VSkeletonLoader,{attrs:{"type":"button"}})]:[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","to":_vm.newAnnouncementLink('author-requests')}},[_vm._v(" Request Amp ")]),_c(VBtn,{attrs:{"to":_vm.newAnnouncementLink()}},[_vm._v("I'll Do It Myself")])]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }