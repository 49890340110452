<template>
    <v-card flat class="rounded-xl">
        <v-row>
            <v-col cols="12" md="7" class="pt-0 pr-md-0">
                <template v-if="loading">
                    <v-card-text>
                        <v-skeleton-loader type="heading" />

                        <v-skeleton-loader type="sentences" class="mt-8" />
                    </v-card-text>
                </template>

                <template v-else>
                    <v-card-title class="font-weight-bold">
                        {{ title }}
                    </v-card-title>

                    <v-card-text>
                        <!-- eslint-disable vue/no-v-html -->
                        <p
                            class="ma-0 font-weight-regular secondary--text"
                            v-html="message"
                        ></p>
                        <p
                            v-if="hasPressCableDistributionCredits"
                            class="ma-0 mt-2 font-weight-bold"
                        >
                            You have PressCable credits remaining. Upgrade to
                            Amp Credits
                            <router-link to="/profile/credits">
                                Here
                                <v-icon color="primary" x-small>
                                    arrow-right
                                </v-icon>
                            </router-link>
                        </p>
                        <!-- eslint-enable vue/no-v-html -->
                    </v-card-text>
                </template>
            </v-col>

            <v-col
                cols="12"
                md="5"
                class="d-flex align-center justify-center pl-md-0"
            >
                <template v-if="loading">
                    <v-skeleton-loader type="button" class="mr-2" />

                    <v-skeleton-loader type="button" />
                </template>

                <template v-else>
                    <v-btn
                        color="primary"
                        class="mr-2"
                        :to="newAnnouncementLink('author-requests')"
                    >
                        Request Amp
                    </v-btn>

                    <v-btn :to="newAnnouncementLink()">I'll Do It Myself</v-btn>
                </template>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

const DashboardMessageProps = Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        hasPublishedAmps: {
            type: Boolean,
            required: true
        },
        clientId: {
            type: String,
            default() {
                return 'null';
            }
        }
    }
});

@Component({
    computed: {
        ...mapState('user', ['firstname', 'businessName'])
    }
})
export default class DashboardMessage extends DashboardMessageProps {
    firstname!: string;
    businessName!: string;

    get title() {
        return this.hasPublishedAmps
            ? `Welcome back, ${this.firstname}`
            : `Welcome to ${this.businessName}, ${this.firstname}`;
    }

    get hasPressCableDistributionCredits() {
        return this.$store.state.user.hasPressCableCredits;
    }

    get message() {
        return this.hasPublishedAmps
            ? `This is your <strong>Progress Dash</strong>, where you can track improvements to your metrics as you publish amps. Your Brand Momentum and Amp Rate only stay healthy if you are scheduling amps regularly.`
            : `This is your <strong>Progress Dash</strong>, where you will be able to track improvements to your metrics as you publish amps. You are seeing average customer data until you publish an amp and will be able to see your own data. The only way to get your results is to start publishing content.`;
    }

    newAnnouncementLink(type = 'announcements') {
        if (this.clientId === 'null') {
            return `/${type}/create`;
        }

        return `/${type}/create?user_id=${this.clientId}`;
    }
}
</script>
