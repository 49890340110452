<template>
    <div v-if="cannotSeeDashboard">
        <welcome-slider />

        <welcome-banner v-if="$vuetify.breakpoint.mdAndUp" />

        <press-cable-user-welcome />

        <training-section class="mt-10" />

        <training-link v-if="canSeeTrainingLink" class="mt-4" />
    </div>

    <div v-else>
        <press-cable-user-welcome />

        <reseller-welcome v-if="shouldWelcomeReseller" />

        <dashboard />
    </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapState } from 'vuex';

import { DefinedRole } from '@/store/modules/User/Role';

import { WelcomeSlider, Dashboard } from './sections';
import { WelcomeBanner } from '@/components/WelcomeBanner';
import { PressCableUserWelcome } from '@/components/PressCableUserWelcome';
import { ResellerWelcome } from '@/components/ResellerWelcome';
import { SimplerTrafficWelcome } from '@/components/SimplerTrafficWelcome';

import { TrainingSection, TrainingLink } from '@/components/Training';
import { Training } from '@/mixins';

@Component({
    components: {
        WelcomeSlider,
        WelcomeBanner,
        TrainingSection,
        TrainingLink,
        Dashboard,
        ResellerWelcome,
        PressCableUserWelcome,
        SimplerTrafficWelcome
    },
    computed: {
        ...mapState('user', [
            'role',
            'isNewSimplerTrafficBuyer',
            'isDropServeBuyer',
            'isAsigoBuyer'
        ])
    }
})
export default class Welcome extends mixins(Training) {
    role!: DefinedRole;
    isDropServeBuyer!: boolean;
    isAsigoBuyer!: boolean;
    isNewSimplerTrafficBuyer!: boolean;

    dissallowedRoles: DefinedRole[] = ['Author', 'Editor'];

    get cannotSeeDashboard() {
        return this.dissallowedRoles.includes(this.role);
    }

    get shouldWelcomeReseller() {
        return this.isDropServeBuyer || this.isAsigoBuyer;
    }
}
</script>
