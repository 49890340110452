<template>
    <v-row>
        <v-col cols="12">
            <v-card
                v-if="loading"
                flat
                class="loader--wrapper rounded-xl"
                height="27em"
            >
                <v-card-text>
                    <v-skeleton-loader type="heading" />

                    <v-skeleton-loader class="mt-4" type="text" width="70%" />

                    <v-skeleton-loader class="mt-4 image-loader" type="image" />
                </v-card-text>
            </v-card>

            <v-card v-else flat class="stats--wrapper rounded-xl">
                <v-card-title
                    class="font-weight-normal black--text justify-center"
                >
                    Amp Search Appearances
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-icon right small v-bind="attrs" v-on="on">
                                circle-info
                            </v-icon>
                        </template>
                        <span>
                            Total number of Google search
                            <br />
                            ranking results for content published
                            <br />
                            through AmpiFire
                        </span>
                    </v-tooltip>
                </v-card-title>

                <v-card-text>
                    <keyword-rankings-chart
                        :data="data"
                        :show-customer-average="showCustomerAverage"
                    />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { KeywordRankingsData } from '.';

import KeywordRankingsChart from './KeywordRankingsChart.vue';

const KeywordRankingsStatsProps = Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        data: {
            type: Object as PropType<KeywordRankingsData>,
            required: true
        },
        showCustomerAverage: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component({
    components: { KeywordRankingsChart }
})
export default class KeywordRankingsStats extends KeywordRankingsStatsProps {}
</script>

<style lang="scss" scoped>
.loader--wrapper::v-deep {
    .v-card__text {
        height: 100%;

        .v-skeleton-loader {
            &.image-loader {
                height: 320px;

                .v-skeleton-loader__image {
                    height: 100%;
                    border-radius: 4px;
                }
            }
        }
    }
}
</style>
