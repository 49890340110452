import { Chart, Plugin, ChartConfiguration } from 'chart.js';

import { HtmlLegendPluginOptions } from '.';

const getOrCreateLegendList = (_chart: Chart, id: string) => {
    const legendContainer = document.getElementById(id);

    let listContainer = legendContainer?.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'row';
        listContainer.style.margin = '0';
        listContainer.style.padding = '0';
        listContainer.style.alignItems = 'center';
        listContainer.style.justifyContent = 'space-evenly';

        legendContainer?.appendChild(listContainer);
    }

    return listContainer;
};

const HtmlLegendPlugin: Plugin = {
    id: 'htmlLegend',

    afterUpdate(chart: Chart, _args, options: HtmlLegendPluginOptions) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        const items =
            chart.options.plugins?.legend?.labels?.generateLabels?.(chart);

        items?.forEach(item => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.justifyContent = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.maxWidth = '33.3%';
            li.style.width = 'fit-content';

            li.onclick = () => {
                if (item.datasetIndex) {
                    const config = chart.config as ChartConfiguration;

                    const { type } = config;
                    if (type === 'pie' || type === 'doughnut') {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.datasetIndex);
                    } else {
                        chart.setDatasetVisibility(
                            item.datasetIndex,
                            !chart.isDatasetVisible(item.datasetIndex)
                        );
                    }
                }
                chart.update();
            };

            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle as string;
            boxSpan.style.borderColor = item.strokeStyle as string;
            boxSpan.style.borderWidth = `${item.lineWidth}px`;
            boxSpan.style.height = '10px';
            boxSpan.style.width = '10px';
            boxSpan.style.marginRight = '6px';
            boxSpan.style.borderRadius = '50%';
            boxSpan.style.padding = '4px';

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.fontFamily = 'DM Sans';
            textContainer.style.fontWeight = '400';
            textContainer.style.fontSize = '12px';
            textContainer.style.lineHeight = '15px';
            textContainer.style.color = item.fontColor as string;
            textContainer.style.margin = '0';
            textContainer.style.padding = '0';
            textContainer.style.textDecoration = item.hidden
                ? 'line-through'
                : '';

            const text = document.createTextNode(item.text);

            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
};

export default HtmlLegendPlugin;
