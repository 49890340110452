<template>
    <div class="slider-container">
        <v-container class="slider-wrapper">
            <v-carousel
                class="slider"
                light
                hide-delimiter-background
                delimiter-icon="square"
                height="340px"
                interval="8000"
                cycle
            >
                <template #prev="{ on, attrs }">
                    <v-btn v-bind="attrs" large icon :ripple="false" v-on="on">
                        <v-icon>angle-left</v-icon>
                    </v-btn>
                </template>

                <template #next="{ on, attrs }">
                    <v-btn v-bind="attrs" large icon :ripple="false" v-on="on">
                        <v-icon>angle-right</v-icon>
                    </v-btn>
                </template>

                <v-carousel-item
                    v-for="(slide, index) in slides"
                    :key="'slide-' + index"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                >
                    <v-container
                        class="fill-height align-start align-sm-center px-0"
                    >
                        <v-row>
                            <v-col
                                cols="10"
                                offset="1"
                                sm="10"
                                offset-sm="1"
                                md="8"
                                offset-md="1"
                                class="text-center text-sm-left"
                            >
                                <h4
                                    class="text-h6 text-sm-h5 text-md-h4 mb-5 font-weight-bold"
                                >
                                    {{ slide.title }}
                                </h4>
                                <!-- eslint-disable vue/no-v-html -->
                                <p
                                    class="text-body-2 text-sm-body-1 text-md-h6 mb-6 font-weight-regular"
                                    v-html="slide.body"
                                ></p>
                                <!-- eslint-enable vue/no-v-html -->

                                <div
                                    class="d-flex flex-wrap justify-center justify-sm-start mt-14 mt-sm-0"
                                >
                                    <v-btn
                                        v-if="slide.primaryActionButton"
                                        class="mt-6 mt-sm-0 mb-2"
                                        :href="
                                            slide.primaryActionButton.actionUrl
                                        "
                                        color="primary"
                                    >
                                        {{ slide.primaryActionButton.text }}
                                    </v-btn>

                                    <v-btn
                                        v-if="slide.secondaryActionButton"
                                        class="ml-sm-4"
                                        link
                                        :to="
                                            slide.secondaryActionButton
                                                .actionUrl
                                        "
                                    >
                                        {{ slide.secondaryActionButton.text }}
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
            </v-carousel>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

type SliderActionButton = {
    text: string;
    actionUrl: string;
};

type Slide = {
    title: string;
    body: string;
    primaryActionButton: SliderActionButton | null;
    secondaryActionButton: SliderActionButton | null;
};

@Component
export default class WelcomeSlider extends Vue {
    get firstname(): string {
        return this.$store.state.user.firstname;
    }

    get site() {
        return this.$store.state.user.businessName;
    }

    get slides() {
        return this.$can('view', 'AuthorSlides')
            ? this.authorSlides
            : this.customerSlides;
    }

    authorSlides: Array<Slide> = [
        {
            title: 'Welcome to the Writing team',
            body: "Congratulations on being part of our dynamic team of A-players. We hope to have a long and successful journey together. Let's start by learning how we create Amp campaigns for our customers: <a href='/files/documentation/Amp-Writer's-Guide.pdf' target='_blank' class='secondary--text font-weight-bold'>Review The Complete Amp Writer's Guide</a>",
            primaryActionButton: {
                text: 'Claim An Amp',
                actionUrl: '/author_requests'
            },
            secondaryActionButton: null
        },
        {
            title: 'Welcome to the Writing team',
            body: "<a href='/files/documentation/Amp-Checklist.pdf' target='_blank' class='secondary--text font-weight-bold'>Review our Amp Checklist</a> and start creating your first content.",
            primaryActionButton: {
                text: 'Claim An Amp',
                actionUrl: '/author_requests'
            },
            secondaryActionButton: null
        }
    ];

    customerSlides: Array<Slide> = [
        {
            title: `Welcome to ${this.site}, ${this.firstname}`,
            body: `You're all set up: you can <strong>start creating your Amp</strong> or <strong>have an experienced author create one for you.</strong> Our team of professional writers and content marketers will use the information that you provide to build your campaign.`,
            primaryActionButton: {
                text: 'Create Amp For Me',
                actionUrl: '/author_requests/create'
            },
            secondaryActionButton: {
                text: "I'll Do It Myself",
                actionUrl: '/announcements/create'
            }
        },
        {
            title: 'Recurring Amps',
            body: "<strong>Request a Recurring Amp</strong> and we'll create an Amp every single month.",
            primaryActionButton: {
                text: 'Create Recurring Amp For Me',
                actionUrl: '/author_requests/create?campaign=1'
            },
            secondaryActionButton: null
        },
        {
            title: 'In-Depth Training',
            body: 'While you can start creating Amps immediately, we highly recommend that you check out the <strong>training first.</strong>',
            primaryActionButton: {
                text: 'Review Training Materials',
                actionUrl: '/training'
            },
            secondaryActionButton: null
        }
    ];
}
</script>

<style lang="scss" scoped>
.slider-container::v-deep {
    background-color: $blueish;

    .slider-wrapper {
        background-image: url('@/assets/img/slider-background.svg');
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: right;
        padding-top: 0;
        padding-bottom: 0;
        background-size: 35%;

        .v-carousel {
            .v-window__container {
                .v-window__prev {
                    margin: 0 !important;
                    background-color: unset !important;

                    .v-btn:before {
                        background-color: unset !important;
                    }
                }

                .v-window__next {
                    margin: 0 !important;
                    background-color: unset !important;

                    .v-btn:before {
                        background-color: unset !important;
                    }
                }
            }

            .v-carousel__controls {
                .v-item-group {
                    .v-carousel__controls__item {
                        margin: 0 6px 0 0;

                        &:before {
                            background-color: transparent !important;
                        }

                        .v-btn__content svg {
                            opacity: 1 !important;
                        }

                        .v-ripple__container {
                            visibility: hidden;
                        }

                        &:not(.v-item--active) {
                            .v-btn__content svg {
                                opacity: 0.2 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .slider-container::v-deep {
        .slider-wrapper {
            background-size: contain;

            .v-carousel {
                height: 440px !important;

                .v-carousel__item {
                    height: 440px !important;
                }
            }
        }
    }
}

@media (min-width: 1900px) {
    .slider-container::v-deep {
        .slider-wrapper {
            background-size: contain;
        }
    }
}
</style>
