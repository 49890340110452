<template>
    <div class="amp-rate--wrapper">
        <p class="amp-rate__title">
            Amp Rate
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">circle-info</v-icon>
                </template>

                <span>Rate of recent amp publication per month</span>
            </v-tooltip>
        </p>

        <div class="amp-rate__progress--wrapper">
            <div :class="`amp-rate__progress ${currentProgressClass}`">
                <v-icon :class="`icon ${progressDirection}`">
                    arrow-right
                </v-icon>
            </div>

            <span class="amp-rate__progress-count">{{ data.current }}</span>
        </div>

        <small>{{ comparisonLabel }}</small>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { AmpRate as AmpRateData } from '.';

const AmpRateProps = Vue.extend({
    props: {
        data: {
            type: Object as PropType<AmpRateData>,
            required: true
        }
    }
});

@Component
export default class AmpRate extends AmpRateProps {
    currentProgressClass = '';

    progressDirection = '';

    setCurrentProgressClass() {
        switch (this.data.current) {
            case 0:
                return '';

            case 1:
                return 'progress-25';

            case 2:
                return 'progress-50';

            case 3:
                return 'progress-75';

            default:
                return 'progress-100';
        }
    }

    mounted() {
        setTimeout(() => {
            this.setProgress();
        }, 500);
    }

    get comparisonLabel() {
        const { current, previous, comparison } = this.data;

        if (comparison === 0) {
            return `${comparison}% from 30 days ago`;
        }

        const prefix = current >= previous ? '+' : '';

        return `${prefix}${comparison}% from 30 days ago`;
    }

    setProgress() {
        const { current, previous } = this.data;

        if (current >= previous) {
            this.progressDirection = 'up';
        } else {
            this.progressDirection = 'down';
        }

        this.currentProgressClass = this.setCurrentProgressClass();
    }
}
</script>

<style lang="scss" scoped>
.amp-rate--wrapper::v-deep {
    text-align: center;

    .amp-rate__title {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        color: black;
    }

    .amp-rate__progress--wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .amp-rate__progress {
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(45deg);
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 4px solid transparent;
            transition: border 0.5s;

            &.progress-25 {
                border-top: 4px solid $primary-color;
            }

            &.progress-50 {
                border-top: 4px solid $primary-color;
                border-right: 4px solid $primary-color;
            }

            &.progress-75 {
                border-top: 4px solid $primary-color;
                border-right: 4px solid $primary-color;
                border-bottom: 4px solid $primary-color;
            }

            &.progress-100 {
                border-top: 4px solid $primary-color;
                border-right: 4px solid $primary-color;
                border-bottom: 4px solid $primary-color;
                border-left: 4px solid $primary-color;
            }

            .icon {
                transform: rotate(-45deg);
                transition: transform 0.5s;

                &.up {
                    transform: rotate(-90deg);
                }

                &.down {
                    transform: rotate(0deg);
                }
            }
        }

        .amp-rate__progress-count {
            font-size: 20px;
            font-weight: 500;
            margin-left: 0.75em;
        }
    }

    small {
        font-size: 12px;
    }
}
</style>
