<template>
    <v-row>
        <v-col cols="12" class="brand-mentions--container">
            <v-card
                v-if="loading.link_count"
                flat
                class="brand-mentions__loader--wrapper rounded-xl"
            >
                <v-card-text class="d-flex align-center justify-center py-1">
                    <v-skeleton-loader
                        type="list-item-avatar-two-line"
                        width="20em"
                    />
                </v-card-text>
            </v-card>

            <v-card
                v-else
                flat
                class="brand-mentions__stats--wrapper rounded-xl"
            >
                <v-avatar color="primary" size="32px">
                    <v-icon small color="white" class="rotate-up-45">
                        arrow-right
                    </v-icon>
                </v-avatar>

                <v-card-text>
                    <v-card-title>
                        <a-count-up
                            :value="Number(data.linkCount.total)"
                            suffix=" Brand Mentions"
                        />

                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-icon right small v-bind="attrs" v-on="on">
                                    circle-info
                                </v-icon>
                            </template>
                            <span>
                                Total content placements achieved through
                                AmpiFire
                            </span>
                        </v-tooltip>
                    </v-card-title>

                    <v-card-subtitle>
                        <a-count-up
                            :value="Number(data.linkCount.latest)"
                            prefix="+"
                            suffix=" since last amp"
                        />
                    </v-card-subtitle>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" class="brand-momentum--container">
            <v-card
                v-if="loading.brand_momentum"
                flat
                class="brand-momentum__loader--wrapper rounded-xl"
            >
                <v-card-text>
                    <v-skeleton-loader type="text" width="40%" />

                    <v-skeleton-loader
                        class="image-loader mt-4"
                        type="image"
                        width="100%"
                    />
                </v-card-text>
            </v-card>

            <v-card
                v-else
                flat
                class="brand-momentum__guage--wrapper rounded-xl"
            >
                <v-card-text
                    class="d-flex flex-column align-center justify-start"
                >
                    <div class="guage-heading">
                        <p class="guage-title">
                            Brand Momentum
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs" v-on="on">
                                        circle-info
                                    </v-icon>
                                </template>

                                <span>
                                    AmpiFire's formula for calculating recent
                                    <br />
                                    changes in your appearances and rankings
                                    <br />
                                    relative to your amp publication rate
                                </span>
                            </v-tooltip>
                        </p>

                        <small>{{ status }}</small>
                    </div>

                    <div class="guage--container">
                        <div class="guage--wrapper">
                            <div class="guage-needle" :style="style"></div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { ACountUp } from '@/components/ACountUp';

import { BrandData } from '.';
import { Watch } from '@/utils/decorators';

const BrandStatsProps = Vue.extend({
    props: {
        loading: {
            type: Object,
            default() {
                return {
                    link_count: false,
                    brand_momentum: false
                };
            }
        },
        data: {
            type: Object as PropType<BrandData>,
            required: true
        }
    }
});

@Component({
    components: { ACountUp }
})
export default class BrandStats extends BrandStatsProps {
    needleAngle = 0;

    @Watch('data.momentumRate')
    onMomentumRateChange(v: number) {
        this.calculateNeedleAngle(v);
    }

    calculateNeedleAngle(momentumRate: number) {
        setTimeout(() => {
            if (momentumRate > 100) {
                this.needleAngle = 180;

                return;
            }

            this.needleAngle = (momentumRate / 100) * 180;
        }, 500);
    }

    get status() {
        return this.data.momentumRate === 0
            ? '0% change since last amp'
            : `+${this.data.momentumRate}% change since last amp`;
    }

    get style() {
        return {
            '--needle-angle': `${this.needleAngle}deg`
        };
    }

    mounted() {
        this.calculateNeedleAngle(this.data.momentumRate);
    }
}
</script>

<style lang="scss" scoped>
:root {
    --needle-angle: 0deg;
}

.brand-mentions--container {
    .brand-mentions__loader--wrapper {
        .v-card__text {
            height: 100%;
        }
    }

    .brand-mentions__stats--wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .v-avatar {
            .v-icon {
                transform: rotate(-45deg);
            }
        }

        .v-card__text {
            padding: 0;
            width: fit-content;

            .v-card__title {
                font-weight: 500;
                font-size: 18px;
                color: black;
                padding: 16px 8px 8px;
            }

            .v-card__subtitle {
                font-weight: 400;
                font-size: 12px;
                color: $secondary-color;
                padding-left: 10px;
            }
        }
    }
}

.brand-momentum--container::v-deep {
    .brand-momentum__loader--wrapper {
        .v-card__text {
            height: 23.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .v-skeleton-loader {
                &.image-loader {
                    height: 20em;

                    .v-skeleton-loader__image {
                        height: 100%;
                    }
                }
            }
        }
    }

    .brand-momentum__guage--wrapper {
        .v-card__text {
            height: 23.5em;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;

            .guage-heading {
                text-align: center;
                .guage-title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 40px;
                    color: black;
                    margin: 0;
                }

                small {
                    font-size: 12px;
                }
            }

            .guage--container {
                height: 100%;
                width: 100%;

                .guage--wrapper {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    height: inherit;
                    width: inherit;
                    background-image: url('@/assets/img/guage.svg');
                    background-position: center;

                    .guage-needle {
                        height: 7em;
                        width: 10em;
                        background-image: url('@/assets/img/needle.svg');
                        background-position: calc(50% - 26px) 50%;
                        background-size: 5em;
                        transform: rotate(var(--needle-angle));
                        transition: transform 0.5s;
                    }
                }
            }
        }
    }
}
</style>
