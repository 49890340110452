<template>
    <v-container>
        <v-row justify="center">
            <v-dialog
                v-if="shouldWelcomeDropServe"
                v-model="dialog"
                persistent
                max-width="500"
            >
                <v-card class="modal-wrapper">
                    <v-card-title class="justify-center">
                        <h3 class="py-4">Welcome To DropServe!</h3>
                        <v-img
                            :max-height="
                                $vuetify.breakpoint.mobile ? 'auto' : '20vh'
                            "
                            :max-width="
                                $vuetify.breakpoint.mobile ? '30vw' : 'auto'
                            "
                            contain
                            src="@/assets/img/success.png"
                        />
                        <v-btn
                            icon
                            color="secondary"
                            class="close-dialog-btn"
                            @click="closeWelcomeDialog"
                        >
                            <v-icon>fa-xmark</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="modal-body">
                        <v-stepper v-model="onboarding" elevation="0">
                            <v-stepper-items>
                                <v-stepper-content step="1" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="pb-0 text-h6">
                                            RSVP For Your Live DropServe
                                            Training
                                        </v-card-subtitle>

                                        <v-card-text>
                                            <p>
                                                Congrats on joining DropServe!
                                                It is highly recommended that
                                                you join the live training
                                                sessions so you can ask
                                                questions, maintain your
                                                progress each week, and hit your
                                                goals. Recordings & weekly tasks
                                                will be made available here in
                                                your members' area so you can
                                                follow-on at your own pace.
                                            </p>
                                            <v-btn
                                                href="https://go.ampifire.ai/DSTraining"
                                                target="_blank"
                                                color="primary"
                                                class="py-8 py-sm-4"
                                                :block="
                                                    $vuetify.breakpoint.mobile
                                                "
                                            >
                                                ENROLL FOR YOUR TRAINING HERE
                                            </v-btn>
                                        </v-card-text>

                                        <v-card-actions class="justify-end">
                                            <v-btn @click="onboarding = 2">
                                                Next
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-content step="2" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="pb-0 text-h6">
                                            Maximize Your Profits &amp; eStore
                                            Growth
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <p>
                                                You currently receive a single
                                                Amp Credit each month for free,
                                                as part of your eStore
                                                maintenance & whitelabel tools
                                                subscription fee. This means you
                                                can fulfill your first client
                                                each month without any
                                                additional cost.
                                            </p>
                                            <p>
                                                If you want to grow your
                                                DropService business and make
                                                more sales for even bigger
                                                profit margins, stock up on Amp
                                                Credits below at our best rates!
                                            </p>
                                            <v-btn
                                                color="primary"
                                                href="https://www.dropserve.ai/activate"
                                                target="_blank"
                                                :block="
                                                    $vuetify.breakpoint.mobile
                                                "
                                            >
                                                Stock Up On Amp Credits
                                            </v-btn>
                                        </v-card-text>
                                        <v-card-actions
                                            class="justify-space-between"
                                        >
                                            <v-btn @click="onboarding = 1">
                                                Previous
                                            </v-btn>
                                            <v-btn @click="onboarding = 3">
                                                Next
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-content step="3" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="pb-0 text-h6">
                                            Meet Your Fellow "Droppers"
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <p>
                                                Join our Private DropServe
                                                Facebook group where we’ll be
                                                sharing all our best strategies
                                                and supplementing your training.
                                            </p>
                                            <p>
                                                Take part in with the victories,
                                                competitions, advice and more
                                                alongside your fellow DropServe
                                                Partners and DropServicing
                                                entrepreneurs (a.k.a.
                                                "Droppers").
                                            </p>
                                            <v-btn
                                                color="primary"
                                                href="https://www.facebook.com/groups/dropservegroup"
                                                target="_blank"
                                                class="py-8"
                                                :block="
                                                    $vuetify.breakpoint.mobile
                                                "
                                            >
                                                Join Our Private Facebook Group
                                                - Introduce Yourself!
                                            </v-btn>
                                        </v-card-text>

                                        <v-card-actions
                                            class="justify-space-between"
                                        >
                                            <v-btn @click="onboarding = 2">
                                                Previous
                                            </v-btn>
                                            <v-btn @click="onboarding = 4">
                                                Next
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-content step="4" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="pb-0 text-h6">
                                            Book Your 1-To-1 Session!
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <p>
                                                When you invested in
                                                "DropServe", as an additional
                                                perk, you were given a free
                                                one-to-one onboarding & strategy
                                                session with one of our
                                                DropServicing Experts.
                                            </p>
                                            <p>
                                                This is going to help you tailor
                                                and define your own personal
                                                strategy and roadmap.
                                            </p>
                                            <p>
                                                These sessions usually cost $500
                                                but your session is FREE.
                                            </p>
                                            <v-btn
                                                color="primary"
                                                href="https://dropserve.ai/thankyou-2024"
                                                target="_blank"
                                                :block="
                                                    $vuetify.breakpoint.mobile
                                                "
                                            >
                                                Book Your 1-To-1 Here
                                            </v-btn>
                                        </v-card-text>
                                        <v-card-actions
                                            class="justify-space-between"
                                        >
                                            <v-btn @click="onboarding = 3">
                                                Previous
                                            </v-btn>
                                            <v-btn @click="onboarding = 5">
                                                Next
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-content step="5" class="pa-0">
                                    <v-card class="text-center">
                                        <v-card-subtitle class="pb-0 text-h6">
                                            Got Your First Client? Apply to
                                            scale...
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <p>
                                                Once you've made your first
                                                sales or if you already have
                                                some experience and want to grow
                                                faster and scale bigger - you
                                                may qualify for our “Ascension
                                                Program” for personal coaching,
                                                and to be part of an elite
                                                community of Droppers going to 6
                                                figures and beyond.
                                            </p>
                                            <p>
                                                Join our friendly competition &
                                                weekly coaching & accountability
                                                to grow your recurring revenues
                                                and add $10k/mo in new revenue
                                                within 90 days, and rub
                                                shoulders with our experts and
                                                most successful Droppers.
                                            </p>
                                            <v-btn
                                                color="primary"
                                                href="https://get.ampifire.com/ascension?__hstc=171725375.845d9643dde1fe16b63a19d12f821abf.1721302575114.1721831128690.1721834180576.8&__hssc=171725375.1.1721834180576&__hsfp=3820732663"
                                                target="_blank"
                                                class="py-8 py-sm-4"
                                                :block="
                                                    $vuetify.breakpoint.mobile
                                                "
                                            >
                                                Apply For The Ascension Program
                                                Here
                                            </v-btn>
                                        </v-card-text>
                                        <v-card-actions
                                            class="justify-space-between"
                                        >
                                            <v-btn @click="onboarding = 3">
                                                Previous
                                            </v-btn>
                                            <v-btn @click="dialog = false">
                                                Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <modal
                :id="confirmationDialogId"
                :closable="false"
                title="Disable Onboarding Information"
            >
                <template #default>
                    <p>
                        This information helps you get started. Would you like
                        to see it next time or never again?
                    </p>
                </template>
                <template #actions>
                    <v-btn
                        text
                        :block="$vuetify.breakpoint.smAndDown"
                        @click="returnToWelcomeDialog"
                    >
                        Show Me Later
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        :block="$vuetify.breakpoint.smAndDown"
                        @click="keepWelcomeDialogClosed"
                    >
                        Never Show Me It Again
                    </v-btn>
                </template>
            </modal>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { Modal } from '@/components/Modal';

@Component({
    computed: {
        ...mapState('user', ['isNewDropServeBuyer'])
    },
    components: {
        Modal
    }
})
export default class ResellerWelcome extends Vue {
    isNewDropServeBuyer!: boolean;

    onboarding = 1;
    dialog = true;
    confirmKeepClosedDialog = false;
    confirmationDialogId = 'confirmKeepClosedDialog';

    get shouldWelcomeDropServe() {
        return this.isNewDropServeBuyer && !this.hasSeenWelcomeDialog();
    }

    get storageKey() {
        return `closed_reseller_welcome_${this.$store.state.user.id}`;
    }

    hasSeenWelcomeDialog() {
        const localStorage = window.localStorage;
        if (!localStorage) {
            return false;
        }
        return localStorage.getItem(this.storageKey);
    }

    closeWelcomeDialog() {
        this.dialog = false;
        this.$store.dispatch('modal/open', this.confirmationDialogId);
    }

    returnToWelcomeDialog() {
        this.$store.dispatch('modal/close', this.confirmationDialogId);
        this.dialog = true;
    }

    keepWelcomeDialogClosed() {
        this.$store.dispatch('modal/close', this.confirmationDialogId);
        localStorage.setItem(this.storageKey, `${this.$store.state.user.id}`);
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper::v-deep {
    .v-card > .v-card__subtitle,
    .v-card__text {
        color: $secondary-color;
    }

    .v-sheet {
        box-shadow: none;
    }

    .v-btn__content {
        max-width: 100%;
        white-space: normal;
    }
}

.close-dialog-btn {
    position: absolute;
    top: 1em;
    right: 1em;
}
</style>
